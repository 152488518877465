import React, {Suspense} from 'react'
import ReactDOM from 'react-dom/client'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

const App = React.lazy(() => import('./App'))
const ChatApp = React.lazy(() => import('./ChatApp'))
const TheScribeApp = React.lazy(() => import('./TheScribeApp'))

const root = ReactDOM.createRoot(document.getElementById('root'))
const getApp = (ovenAppId) => {
  switch(ovenAppId) {
    case 'fn-ai-io':
      return <Suspense><ChatApp /></Suspense>
    case 'thescribe-ai':
      return <Suspense><TheScribeApp /></Suspense>
    default:
      return <Suspense><App /></Suspense>
  }
}
const experience = process.env.REACT_APP_OVEN_EXPERIENCE
const ovenAppId = process.env.REACT_APP_OVEN_APP_ID
console.log('exp:', experience)
root.render(
  <React.StrictMode>
    {getApp(ovenAppId)}
  </React.StrictMode>
)
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
